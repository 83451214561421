import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import CategoryArchive from '../components/CategoryArchive';

const NotFoundPage = ({ data }) => (
  <Wrapper className="not-found" title={'Page Not Found'}>
    <CategoryArchive
      title="Page Not Found"
      paintings={data.firstNinePaintings.edges.map(edge => ({
        ...edge.node,
      }))}
      rest={data.restOfPaintings.edges.map(edge => ({ ...edge.node }))}
    />
  </Wrapper>
);

export const ErrorGalleryQuery = graphql`
  {
    firstNinePaintings: allSanityPainting(sort: { fields: _id }, limit: 9) {
      edges {
        node {
          ...GalleryPainting
        }
      }
    }
    restOfPaintings: allSanityPainting(
      sort: { fields: _id }
      limit: 9
      skip: 9
    ) {
      edges {
        node {
          ...GalleryPainting
        }
      }
    }
  }
`;
export default NotFoundPage;
